import React, { useState } from 'react';
import './App.css';
import axios from 'axios';
import { backendIP } from './myconfigurations';

function App() {

  const [sitlStatus, setSitlStatus] = useState("Not Started")
  const [userDetails, setUserDetails] = useState({ userEmail: "", password: "", droneID: "",lat:"25.647599838681714",long:"90.32645678880044",asl:"610",heading:"60" })
  const updateUserDetails = () => {
    setUserDetails({ ...userDetails })
  }
  const handleGetStatusClick = async () => {
    if (userDetails.userEmail !== "" && userDetails.password !== "") {
      try {
        const getStatusResp = await axios(`${backendIP}/get_sitl_status?useremail=${userDetails.userEmail}&username=${userDetails.password}&droneid=${userDetails.droneID}`)
        console.log("getStatusResp", getStatusResp.data)
        setSitlStatus(getStatusResp.data)
      }
      catch (err: any) {
        console.log(err)
        // window.alert("Some error occered while getting sitl status")
        window.alert(err.response.data)
      }
    }
    else {
      window.alert("all fields are mandatory")
    }
  }

  const handleStopSitlClick = async () => {
    if (userDetails.userEmail !== "" && userDetails.password !== "") {
      try {
        const killSitlResp = await axios(`${backendIP}/kill?useremail=${userDetails.userEmail}&username=${userDetails.password}&droneid=${userDetails.droneID}`)
        console.log("killSitlResp", killSitlResp.data)
        setSitlStatus(killSitlResp.data)
      }
      catch (err: any) {
        console.log(err)
        // window.alert("Some error occered while killing sitl instance")
        window.alert(err.response.data)
      }
    }
    else {
      window.alert("all fields are mandatory")
    }
  }

  const handleStartSitlClick = async () => {
    if (userDetails.userEmail !== "" && userDetails.password !== "") {
      try {
        const startSitlResp = await axios(`${backendIP}/new_sitl_start`, {
          method: "POST",
          data: { ...userDetails }
        })
        console.log("startSitlResp", startSitlResp.data)
        setSitlStatus(startSitlResp?.data?.data?.sitl_status)
        userDetails.droneID=startSitlResp?.data?.data?.droneId
        updateUserDetails()
      }
      catch (err: any) {
        console.log(err)
        // window.alert("Some error occered while killing sitl instance")
        window.alert(err.response?.data?.message)
      }
    }
    else {
      window.alert("all fields are mandatory")
    }
  }

  return (
    <div className="flex flex-col items-center justify-start border border-black">
      <div className='w-1/5 flex flex-row items-center justify-start border border-black px-4 py-1'>
        <div className='w-2/5'>User Email</div>
        <div>:</div>
        <input value={userDetails.userEmail} onChange={(e) => { userDetails.userEmail = e.target.value; updateUserDetails() }} type='email' className=''></input>
      </div>
      <div className='w-1/5 flex flex-row items-center justify-start border border-black px-4 py-1'>
        <div className='w-2/5'>Password</div>
        <div>:</div>
        <input value={userDetails.password} onChange={(e) => { userDetails.password = e.target.value; updateUserDetails() }} type='password' className=''></input>
      </div>
      <div className='w-1/5 flex flex-row items-center justify-start border border-black px-4 py-1'>
        <div className='w-2/5'>DroneId</div>
        <div>:</div>
        <input readOnly value={userDetails.droneID} onChange={(e) => { userDetails.droneID = e.target.value; updateUserDetails() }} type='text' className=''></input>
      </div>
      <div className='w-1/5 flex flex-row items-center justify-around border border-black px-4 py-1'>
        <div className='w-2/5 justify-self-start'>SITL status</div>
        <div className=' justify-self-start'>:</div>
        <div className=''>{sitlStatus}</div>
        <button className='p-1 border border-black bg-slate-400 hover:bg-black hover:text-white rounded-md '
          onClick={handleGetStatusClick}>Get Status</button>
      </div>
      <div className='w-1/5 flex flex-row items-center justify-around border border-black px-4 py-1'>
        <div className='w-2/5 justify-self-start'>latitude</div>
        <input value={userDetails.lat} onChange={(e) => { userDetails.lat = e.target.value; updateUserDetails() }} type='number' className=''></input>
      </div>
      <div className='w-1/5 flex flex-row items-center justify-around border border-black px-4 py-1'>
        <div className='w-2/5 justify-self-start'>longitude</div>
        <input value={userDetails.long} onChange={(e) => { userDetails.long = e.target.value; updateUserDetails() }} type='number' className=''></input>
      </div>
      <div className='w-1/5 flex flex-row items-center justify-around border border-black px-4 py-1'>
        <div className='w-2/5 justify-self-start'>ASL(alt[m])</div>
        <input value={userDetails.asl} onChange={(e) => { userDetails.asl = e.target.value; updateUserDetails() }} type='number' className=''></input>
      </div>
      <div className='w-1/5 flex flex-row items-center justify-around border border-black px-4 py-1'>
        <div className='w-2/5 justify-self-start'>Heading</div>
        <input value={userDetails.heading} onChange={(e) => { userDetails.heading = e.target.value; updateUserDetails() }} type='number' className=''></input>
      </div>
      <div className='w-1/5 flex flex-row items-center justify-around border border-black px-4 py-1'>
        <button onClick={handleStartSitlClick} className='p-1 border border-black bg-slate-400 hover:bg-black hover:text-white rounded-md'>Start SITL</button>
        <button onClick={handleStopSitlClick} className='p-1 border border-black bg-slate-400 hover:bg-black hover:text-white rounded-md'>Kill SITL</button>
      </div>
    </div>
  );
}

export default App;
